.header {
  width: 100%;
  padding: 3px 0;
  background-color: black;
}
.header h1 {
  padding-left: 20px;
  font-size: 15pt;
  color: white;
}
.header .copyright {
  position: absolute;
  right: 10px;
}
.header .copyright a {
  font-size: 10pt;
  color: white;
}

.logo {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  overflow: hidden;
}
.logo .dice {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background-image: url("dice.gif");
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px 20px #ebe9eb inset;
  z-index: 1;
}
.logo .plate {
  width: 300px;
  height: 300px;
  margin: -110px auto;
  background-image: url("plate.png");
  background-size: cover;
  z-index: 0;
}

.body {
  width: 300px;
  margin: 0 auto;
}
